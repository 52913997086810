
import { Vue, Component  } from "vue-property-decorator";
import { EmpreendimentoService, PessoaService, TipoAditamentoContrato, UsuarioService } from "@/core/services/cadastros";
import { CentroCustoService, ContaCorrenteService, ContaGerencialService, FormaPagamentoService, SituacaoDespesaParcelaService, SituacaoReceitaParcelaService, TipoDespesaService, TipoReceitaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgDespesa extends Vue {
    filter: {
      empreendimentoId: any;
      formaPagamento:string;
      usuarioBaixa:string; 
      fornecedorId:string;
      situacaoParcelaId:any;
      tipoDespesaId: any;
      dataVencimentoInicial:string;
      dataVencimentoFinal:string;
      dataBaixaInicial:string;
      dataBaixaFinal:string;
      dataEmissaoInicial:string;
      dataEmissaoFinal:string;
      empresaId:any,
      despesaId:any,
      numeroDocumento:string,
      centroCustoId:any,
      contaGerencialId:any
      contaCorrenteId:number;
      campos:any []
    } = {
    empreendimentoId: 0,
    formaPagamento:"",
    usuarioBaixa:"",
    fornecedorId:"",
    situacaoParcelaId:0,
    tipoDespesaId:0,
    dataVencimentoInicial:"",
    dataVencimentoFinal:"",
    dataBaixaInicial:"",
    dataBaixaFinal: "",
    dataEmissaoInicial: "",
    dataEmissaoFinal: "",
    empresaId: 0,
    despesaId:0,
    numeroDocumento:"",
    centroCustoId:0,
    contaGerencialId:0,
    contaCorrenteId:0,
    campos: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,16,17,18]
  };

  headers = [
    {id:1, nome:"Id"},
    {id:2, nome:"Nº da Parcela"},
    {id:3, nome:"Número do Documento"},
    {id:4, nome:"Chave"},
    {id:5, nome:"Empreendimento"},
    {id:6, nome:"Empresa"},
    {id:7, nome:"Fornecedor"},
    {id:8, nome:"CPF/CNPJ do Fornecedor"},
    {id:9, nome:"Data de Vencimento"},
    {id:10, nome:"Data de Pagamento"},
    {id:11, nome:"Valor da Parcela"},
    {id:12, nome:"Situação da Parcela"},
    {id:13, nome:"Forma de Pagamento"},
    {id:14, nome:"Encargos"},
    {id:15, nome:"Descontos"},
    {id:16, nome:"Valor Pago Parcela"},
    {id:17, nome:"Valor Pago Despesa"},
    {id:18, nome:"Responsável pela Baixa"},
    {id:19, nome:"Centro de Custo"},
    {id:20, nome:"Conta Gerencial"},
    {id:21, nome:"Conta Corrente"},
    {id:22, nome:"Observação da Despesa"},
    {id:23, nome:"Observação da Parcela"},
    {id:24, nome:"Data de Emissão da Despesa"},
    {id:25, nome:"Tipo"},
  ]

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatorio de Parcela Detalhada em Despesa', disabled: true, href: '/financeiro/painel/despesas'}
  ]


   /* HEADERS */
    SelecionarFiltro(){
      this.$nextTick(() => {
        if (this.selecionarTodosFiltro) {
          this.filter.campos = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,16,17,18]
        } else {
          this.filter.campos = this.headers.slice().map((x:any)=>x.id)
        }
      })
    }

    get selecionarTodosFiltro(){
      return this.filter.campos.length === this.headers.length
    }

    get SelecionarAlgunsFiltro(){
    
      return this.filter.campos.length > 0 && !this.selecionarTodosFiltro  
    }

    get iconFiltro(){
      if (this.selecionarTodosFiltro) return 'mdi-close-box'
      if (this.SelecionarAlgunsFiltro) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textFiltro(){
      if (this.selecionarTodosFiltro) return 'Desmarcar'
      if (this.SelecionarAlgunsFiltro) return 'Selecionando...'
      return 'Selecionar todos'
    }

    /* EMPRESAS */
    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }
    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    } 

   
    /* SITUAÇÃO DA PARCELA */
    SelecionarSituacao(){ 
       this.$nextTick(() => {
          if (this.selecionarTodosSituacao) {
          this.filter.situacaoParcelaId = []
          } else {
            this.filter.situacaoParcelaId = this.situacoes.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosSituacao(){
      return this.filter.situacaoParcelaId.length === this.situacoes.length
    }

    get SelecionarAlgunsSituacao(){
      return this.filter.situacaoParcelaId.length > 0 && !this.selecionarTodosSituacao
    }

    get iconSituacao(){
      if (this.selecionarTodosSituacao) return 'mdi-close-box'
      if (this.SelecionarAlgunsSituacao) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textSituacao(){
      if (this.selecionarTodosSituacao) return 'Desmarcar'
      if (this.SelecionarAlgunsSituacao) return 'Selecionando...'
      return 'Selecionar todos'
    }

    /* TIPO DE RECEITA  */
    SelecionarTipoReceita() {
      this.$nextTick(() => {
          if (this.selecionarTodos) {
          this.filter.tipoDespesaId = []
          } else {
            this.filter.tipoDespesaId = this.tiposDespesas.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodos(){
      return this.filter.tipoDespesaId.length === this.tiposDespesas.length
    }

    get SelecionarAlguns(){
      return this.filter.tipoDespesaId.length > 0 && !this.selecionarTodos
    }

    get icon(){
      if (this.selecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlguns) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get text(){
      if (this.selecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlguns) return 'Selecionando...'
      return 'Selecionar todos'
    }


     /* TIPO DE RECEITA  */
    get iconEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
    get textEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
      return 'Selecionar todos'
    }
    SelecionarEmpreendimento() {
      this.$nextTick(() => {
          if (this.selecionarTodosEmpreendimentos) {
          this.filter.empreendimentoId = []
          } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
          }
      })
    }
    get selecionarTodosEmpreendimentos(){
      return this.filter.empreendimentoId.length === this.empreendimentos.length
    }
    get SelecionarAlgunsEmpreendimentos(){
      return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }


    valid = true;
    pessoas = [];
    formaPagamento:any = [];
    empreendimentos: any = [];
    listaUsuarios:any = []; 
    situacoes:any = [];
    tiposDespesas:any = []; 
    empresas:any = [];
    tipoAditamentos:any = [];
    listaCentroCusto = [];
    listaContaGerencial = [];
    listaContaCorrente = [];
 
  mounted() {
    new ContaCorrenteService().ListarTudo().then(
      res => { this.listaContaCorrente = res.data.items
      } 
    )

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => { 
        this.empresas = res.data.items
      }
    )

    new TipoAditamentoContrato().ListarTudo().then(
      res => { this.tipoAditamentos = res.data.items
      } 
    )

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => { 
        this.empreendimentos = res.data.items
      }
    );

    new FormaPagamentoService().ListarTudo().then(
      res => { 
      this.formaPagamento = res.data.items
      }
    )

    new UsuarioService().Listar(-1, -1, ['login'],[],'',[], '', '' , 'Id, login', '').then(
      res => { 
        this.listaUsuarios = res.data.items
      }
    )

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => {
        this.pessoas = res.data.items
      }
    )

    new SituacaoDespesaParcelaService().ListarTudo().then(
      res => {
        this.situacoes = res.data.items
      }
    ) 

    new TipoDespesaService().ListarTudo().then(
      res => { 
        this.tiposDespesas = res.data.items
    })

    new CentroCustoService().Listagem().then((res) => {
      this.listaCentroCusto = res.data;
    });

    new ContaGerencialService().Listagem().then((res) => {
      this.listaContaGerencial = res.data;
    });
  } 

  Visualizar() {
    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal) && (!this.filter.dataEmissaoInicial || !this.filter.dataEmissaoFinal)){
      this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
      return true;
    }
    
    let routeLink = this.$router.resolve({
      name: "relDespesaDetalhada", 
      query: { 
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        fornecedorId: this.filter.fornecedorId ? this.filter.fornecedorId : "",
        tipoDespesaId: this.filter.tipoDespesaId ? this.filter.tipoDespesaId.toString() : "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        situacaoParcelaId: this.filter.situacaoParcelaId ? this.filter.situacaoParcelaId.toString() : "",
        formaPagamento: this.filter.formaPagamento ? this.filter.formaPagamento : "",
        usuarioBaixa: this.filter.usuarioBaixa ? this.filter.usuarioBaixa : "",
        dataVencimentoInicial:this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial: "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal: "",
        dataBaixaInicial:this.filter.dataBaixaInicial ? this.filter.dataBaixaInicial: "",
        dataBaixaFinal:this.filter.dataBaixaFinal ? this.filter.dataBaixaFinal: "",
        dataEmissaoInicial:this.filter.dataEmissaoInicial ? this.filter.dataEmissaoInicial: "",
        dataEmissaoFinal:this.filter.dataEmissaoFinal ? this.filter.dataEmissaoFinal: "",
        despesaId: this.filter.despesaId ? this.filter.despesaId.toString() : "",
        numeroDocumento:this.filter.numeroDocumento ? this.filter.numeroDocumento: "",
        centroCustoId: this.filter.centroCustoId ? this.filter.centroCustoId.toString() : "",
        contaGerencialId: this.filter.contaGerencialId ? this.filter.contaGerencialId.toString() : "",
        contaCorrenteId: this.filter.contaCorrenteId ? this.filter.contaCorrenteId.toString() : "",
        campos: this.filter.campos ? this.filter.campos.toString() : "",

      },
    });
    window.open(routeLink.href, "_blank");
  }
}
